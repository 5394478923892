import React from 'react';


function AboutArea() {
  
  return (
	<section id="tokenomics" className="about-area about-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-6">
              <div className="about-title">
                <h2 className="title">COMING SOON</h2>
                <div className="about-content">
                  <h6 className="rotate-content">Wst NFT-GAMING</h6>
                  <h2 className="title">Join Our DISCORD To Get <span>Your WHITELIST Spot</span></h2>
                  <p>Wst aims at promoting gaming innovation by efficiently merging two revolutionary technologies — gaming and blockchain. As an all-encompassing ecosystem for gamers and traders, powered by Defi and NFT, Wst’s objective is simple: “to provide an avenue for gamers that is not only entertaining but also allows them to earn.”
                   <a href="/#" target="_blank" rel="noreferrer"> </a> 
                   Our game will be integrated into an NFT digital art marketplace via a decentralized ecosystem built on the Ethereum second-layer solution. By bridging interesting game concepts with the idea of NFTs, the Wst game will deliver a pc-gaming experience unlike anything before.</p>
                  
                    <h4 className="small-title">NFT Minting Price</h4>
                    <h6 className="price">1 <span> NFT PUBLIC PRICE  = 0.1 ETH </span> / MAX 9 NFT PER WALLET</h6>
                    <h6 className="price">1 <span> NFT WHITELIST PRICE = 0.07 ETH </span> / MAX 3 NFT PER WALLET</h6>
                </div>
                  
                <div className="about-btn-wrap">
                 
                  <a href="/#" className="btn discord" target="_blank"><i className="fa-brands fa-discord" /> join discord now</a>
                  <a href="/#" className="btn" target="_blank">OPENSEA</a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="about-img-wrap">
                <img src="assets/img/images/about_img.gif" alt="" />
              </div>
            </div>
          </div>
          <div className="s-about-wrap">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-6">
                <div className="s-about-title">
                  <h2 className="title">UNLEASH THE WARRIOR <span>INSIDE</span></h2>
                </div>
                <div className="s-about-content">
                  <p>Gaming is our passion. That’s why we have developed Wst, a battle royale, Play2Earn multiplayer game with NFT-Skins and weapons in an interactive open-world map. Every character in the game has a unique skill set and hidden abilities unlocked by completing specific challenges during gameplay. As a player you get the chance to 1v1, 5v5, join tournaments and create value by forging, lending and holding Wst NFT’s.</p>
                  <p>Our Vision is to develop modern High-End-Graphics games. Through this development, we aim to increase the value of our token ($KATA) Wst for the community. The games will be integrated into an NFT Digital Art Marketplace via a decentralized Ecosystem. All In-game Items will be NFTs that are available in-game or options to sell in the open market which creates value for every penny spent. For More information please visit our <a href="/">Main Website</a> page and WHITEPAPER</p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <div className="s-about-img">
                  <img src="assets/img/images/about_img02.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default AboutArea