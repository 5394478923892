import React from 'react'

function WstArea() {
  return (
	 <section className="why-katana-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title text-center mb-60">
                <h2 className="title">WHY Wst TOKEN</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-6">
              <div className="features-item">
                <div className="features-icon">
                  <img src="assets/img/icon/features_icon01.png" alt="" />
                </div>
                <div className="features-content">
                  <h4 className="title">HIGH GRAPHICS PC-GAME</h4>
                  <p>It will be a high end PC Game where you can buy NFT Items directly in the Game. These iIems can be purchased with Wst Tokens through our NFT System. All revenues from the Game will be reinvested directly into the Wst Platform to increase Market Cpitalization. These Wst Games are currently in development and will be released to the Public soon.</p>
                </div>
                <span className="features-line" />
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="features-item">
                <div className="features-icon">
                  <img src="assets/img/icon/features_icon02.png" alt="" />
                </div>
                <div className="features-content">
                  <h4 className="title">NFT MARKETPLACE</h4>
                  <p>We are creating our Marketplace for buying and selling Digital Arts and in-Game skins. We are in collaboration with some of the most talented Digital Artists in the World and we’ll help get the best Deals for buying and selling their Products with Wst Tokens. Our NFT Marketplace also will be connected to our Games, allowing for transaction of In-Game Items.</p>
                </div>
                <span className="features-line" />
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="features-item">
                <div className="features-icon">
                  <img src="assets/img/icon/features_icon03.png" alt="" />
                </div>
                <div className="features-content">
                  <h4 className="title">STAKING &amp; IN-GAME REWARDS</h4>
                  <p>Wst allows you to use Coins directly in the Game and Earn passive Income. While playing the Game, you can also earn Money. Also, In-Game Rewards are distributed to the Players. With our Play2Earn Mechanism, we have cool Tournaments where you can participate and win $KATA Tokens. So buckle up, we are coming to Rock your World with the best Play2Earn and Free2Play Games.</p>
                </div>
                <span className="features-line" />
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default WstArea